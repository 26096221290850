<template>
  <div>
    <div>
      <div>
        <h1 class="mr-sm-4 header-tablepage mb-3">Import Config</h1>

        <b-tabs v-model="tabIndex">
          <b-tab title="Customer">
            <div v-if="isLoading">
              <OtherLoading />
            </div>
            <MapFieldConfigComponent
              v-else
              :list="list"
              :form="form"
              :formReq="formReq"
              :errorBorder="errorConfig.length > 0 ? true : false"
              :pageType="1"
              @saveForm="submitForm"
              @moveField="moveField"
            />
          </b-tab>
          <b-tab title="Billing">
            <div v-if="isLoading">
              <OtherLoading />
            </div>
            <MapFieldConfigComponent
              v-else
              :list="list"
              :form="form"
              :formReq="formReq"
              :errorBorder="errorConfig.length > 0 ? true : false"
              :pageType="1"
              @saveForm="submitForm"
              @moveField="moveField"
            />
          </b-tab>
          <b-tab title="Shipping">
            <div v-if="isLoading">
              <OtherLoading />
            </div>
            <MapFieldConfigComponent
              v-else
              :list="list"
              :form="form"
              :formReq="formReq"
              :errorBorder="errorConfig.length > 0 ? true : false"
              :pageType="1"
              @saveForm="submitForm"
              @moveField="moveField"
            />
          </b-tab>
          <b-tab title="Contact">
            <div v-if="isLoading">
              <OtherLoading />
            </div>
            <MapFieldConfigComponent
              v-else
              :list="list"
              :form="form"
              :formReq="formReq"
              :errorBorder="errorConfig.length > 0 ? true : false"
              :pageType="1"
              @saveForm="submitForm"
              @moveField="moveField"
            />
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import MapFieldConfigComponent from "@/components/customer/MapFieldConfigComponent";
import OtherLoading from "@/components/loading/OtherLoading";
export default {
  components: {
    MapFieldConfigComponent,
    OtherLoading,
  },
  data() {
    return {
      list: [],
      form: {
        required: [],
        nonRequired: [],
      },
      formReq: {
        type_column: 1,
        list_import_column: [],
        delete_import_column: [],
      },
      isLoading: true,
      errorConfig: [],
      tabIndex: 0,
    };
  },
  watch: {
    tabIndex(val) {
      console.log(val);
      this.getCustomerConfig(val);
    },
  },
  async created() {
    await this.getCustomerConfig();
  },
  methods: {
    moveField(val, type) {
      if (type) {
        let index = this.form.required.findIndex((el) => el.id == val.id);
        const add = this.form.required.splice(index, 1);

        this.form.nonRequired.push(add[0]);
      } else {
        let index = this.form.nonRequired.findIndex((el) => el.id == val.id);
        const add = this.form.nonRequired.splice(index, 1);

        this.form.required.push(add[0]);
      }
    },
    async getBillingForm() {
      this.formReq.type_column = 3;
      const res = await this.axios("/Import/getimportcustomer/billing");
      return this.tabIndex == 1 && res.data;
    },
    async getShippingForm() {
      this.formReq.type_column = 4;
      const res = await this.axios("/Import/getimportcustomer/shipping");
      return this.tabIndex == 2 && res.data;
    },
    async getContactForm() {
      this.formReq.type_column = 5;
      const res = await this.axios("/Import/getimportcustomer/contact");
      return this.tabIndex == 3 && res.data;
    },
    async getCustomerConfig(val = 0) {
      this.isLoading = true;
      this.form.required = [];
      this.form.nonRequired = [];
      this.formReq.list_import_column = [];
      this.formReq.delete_import_column = [];
      var data = null;
      this.formReq.type_column = 1;
      if (val == 0) {
        await this.$store.dispatch("getCustomerConfigFile");
        data =
          this.tabIndex == 0 && this.$store.state.importFile.customerConfigFile;
      } else if (val == 1) data = await this.getBillingForm();
      else if (val == 2) data = await this.getShippingForm();
      else if (val == 3) data = await this.getContactForm();

      if (!data) return;
      this.isLoading = false;
      if (data.result === 1) {
        this.list = data.detail;
        if (this.errorConfig.length > 0) {
          // error
          for (const field of this.list) {
            const findError = this.errorConfig.find(
              (el) => el.import_column_name === field.name
            );
            // for(const error of this.errorConfig){
            if (findError) {
              if (field.is_required === "1") {
                this.form.required.push({
                  id: field.id,
                  is_required: field.is_required,
                  is_update: field.is_update,
                  import_column_name: field.name,
                  description: field.description,
                  import_column_name_detail: field.excel_column.concat(
                    findError.import_column_name_detail
                  ),
                  error: true,
                });
              } else if (field.is_required === "0") {
                this.form.nonRequired.push({
                  id: field.id,
                  is_required: field.is_required,
                  is_update: field.is_update,
                  import_column_name: field.name,
                  description: field.description,
                  import_column_name_detail: field.excel_column.concat(
                    findError.import_column_name_detail
                  ),
                  error: true,
                });
              }
            } else {
              if (field.is_required === "1") {
                this.form.required.push({
                  id: field.id,
                  is_required: field.is_required,
                  is_update: field.is_update,
                  import_column_name: field.name,
                  description: field.description,
                  import_column_name_detail: field.excel_column,
                  error: false,
                });
              } else if (field.is_required === "0") {
                this.form.nonRequired.push({
                  id: field.id,
                  is_required: field.is_required,
                  is_update: field.is_update,
                  import_column_name: field.name,
                  description: field.description,
                  import_column_name_detail: field.excel_column,
                  error: false,
                });
              }
            }
            // }
          }
        } else {
          // first
          this.form.required = [];
          this.form.nonRequired = [];
          for (const field of this.list) {
            if (field.is_required === "1") {
              this.form.required.push({
                id: field.id,
                is_required: field.is_required,
                import_column_name: field.name,
                description: field.description,
                import_column_name_detail: field.excel_column,
                is_update: field.is_update,
              });
            } else {
              this.form.nonRequired.push({
                id: field.id,
                is_required: field.is_required,
                import_column_name: field.name,
                description: field.description,
                is_update: field.is_update,
                import_column_name_detail: field.excel_column,
              });
            }
          }
        }
        this.isLoading = false;
      }
    },
    async submitForm(value) {
      this.$bus.$emit("showLoading");
      for (const field of this.form.required) {
        // delete field.id;
        // delete field.is_required;
        delete field.description;
        delete field.error;
        if (field.import_column_name_detail.length > 0) {
          let list = [];
          for (const val of field.import_column_name_detail) {
            list.push(val.name);
          }
          const data = {
            id: field.id,
            is_update: field.is_update,
            is_required: field.is_required,
            sort_order: field.sort_order,
            import_column_name: field.import_column_name,
            import_column_name_detail: list,
          };
          this.formReq.list_import_column.push(data);
        }
      }
      for (const non of this.form.nonRequired) {
        // delete non.id;
        // delete non.is_required;
        delete non.description;
        delete non.error;
        if (non.import_column_name_detail.length > 0) {
          let listNon = [];
          for (const nonVal of non.import_column_name_detail) {
            listNon.push(nonVal.name);
          }
          const dataNon = {
            import_column_name: non.import_column_name,
            import_column_name_detail: listNon,
            id: non.id,
            is_update: non.is_update,
            is_required: non.is_required,
            sort_order: non.sort_order,
          };
          this.formReq.list_import_column.push(dataNon);
        }
      }
      await this.$store.dispatch("importConfig", this.formReq);
      const data = this.$store.state.importFile.respImportConfig;
      this.$bus.$emit("hideLoading");
      if (data.result === 1) {
        // this.isLoading = false;
        this.successAlert().then(function () {
          window.location.href = "/report/customer";
        });
        // this.isLoading = true;
      } else {
        // this.isLoading = false;
        this.errorAlert(data.message);

        // this.isLoading = true;
        this.errorConfig = data.detail;
        await this.getCustomerConfig();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
